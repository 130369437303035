import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

const state = {
    user: null,
    loginError: null,
    isProChecked: false,
    subscriptionInfo: null,
};

const getters = {
    user: (state) => state.user,
    loginError: (state) => state.loginError,
    isProChecked: (state) => state.isProChecked,
    subscriptionInfo: (state) => state.subscriptionInfo,
};

const actions = {
    setLoginUser({commit}, user){
        commit('setUser',user);
    },
    async googleSignOut({ commit }) {
        firebase.auth().signOut().then(() => {
            commit('setUser',null);
          }).catch((error) => {
            commit('setError', error.message);
          });
    },
    async getCurrentUser({commit}){
        await firebase.auth().onAuthStateChanged((user)=>{
            commit('setUser',user);
        });
    },
    async getSubsUser({state,commit}){
        axios
          .get('https://www.inspirenignite.com/wp-json/subscription/v1/getsubs?usr_id='+state.user.uid)
          .then((res) => {
            commit('setSubs',res.data);
          });
          commit('setProStatus', true);
    }
};

const mutations = {
    setError: (state, error) => state.loginError = error,
    setUser: (state, user) => state.user = user,
    setSubs: (state, subs) => state.subscriptionInfo = subs,
    setProStatus: (state, proStatus) => state.isProChecked = proStatus,

};


export default {
    state,
    getters,
    actions,
    mutations
}