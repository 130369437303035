<template>
  <v-card class="mt-10">
    <v-card-title> Pro Member Feature </v-card-title>
    <v-card dark
    color="#385F73">
      <v-card-title class="headline">
        Make an informed decision for as low as 699/-
      </v-card-title>

      <v-card-text
        >
        <ul>
          <li>GATE Score Prediction using Artificial Intelligence (AI)</li>
          <li>CCMT Choice filling based on previous ranks, instutitutes and courses</li>
          <li>Compare M.Tech courses based on previous GATE scores, faculty and fee</li>
          <li>All IIT GATE Cutoffs collected from official sources</li>
          <li>All NITs, IIITs, GFTIs GATE Cutoffs collected from official sources</li>
          <li>Round the clock expert support to make perfect admission decisions</li>
          
        </ul>
        </v-card-text
      >
      <v-card-actions class="justify-center">
        <payment-btn />
      </v-card-actions>
    </v-card>
  </v-card>
</template>

<script>
import paymentBtn from "../PaymentBtn/PaymentBtn";

export default {
  name: "PaymentCard",
  components: {
    paymentBtn,
  },
};
</script>

<style scoped src="./PaymentCard.scss" lang="scss"></style>