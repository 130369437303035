import axios from 'axios';
// import { db } from "@/services/firebase";

const state = {
    admissions: null,
    jobs: null,
    isLoading: true,
    adminFav: [],
    adminApplied: [],
    jobFav: [],
    jobApplied: [],
};

const getters = {
    admissions: (state) => state.admissions,
    jobs: (state) => state.jobs,
    isLoading: (state) => state.isLoading,
    adminFav: (state) => state.adminFav,
    adminApplied: (state) => state.adminApplied,
    jobFav: (state) => state.jobFav,
    jobApplied: (state) => state.jobApplied,
    personalAdmins: (state) => state.personalAdmins,
    personalJobs: (state) => state.personalJobs,
};

const actions = {
    async getNotices({ commit, state, rootState}) {
        axios
            .get('https://www.inspirenignite.com/wp-json/compare/v1/getnotices?usr_id='+rootState.user.uid)
            .then((doc) => {
                commit('setAdminNotices', doc.data["admission"]);
                // console.log("notics",doc.data["admission"]);
                commit('setJobNotices', doc.data["job"]);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                state.isLoading = false;
                // get user favorites 
                var url = "https://www.inspirenignite.com/wp-json/compare/v1/getuserfav?usr_id="+rootState.user.uid;
                axios
                    .get(
                        url
                    )
                    .then((res) => {
                        // console.log(res.data);
                        if(res.data.message){
                            return;
                        }
                        res.data.forEach((fav) => {
                            // console.log(fav);
                            for (const admn in state.admissions) {
                                // console.log(admn,state.admissions[admn].id, fav.notice_id);
                                if (fav.fav_category === 'admission' && state.admissions[admn].id === fav.notice_id) {
                                    if (fav.fav_type === 'fav') {
                                        commit('addAdminFav', fav.notice_id);
                                    } else if (fav.fav_type === 'applied') {
                                        commit('addAdminApplied', fav.notice_id);
                                    }

                                }
                            }
                            for (const job in state.jobs) {
                                // console.log(fav.fav_category);
                                if (fav.fav_category === 'job' && state.jobs[job].id === fav.notice_id) {

                                    if (fav.fav_type === 'fav') {
                                        commit('addJobFav', fav.notice_id);
                                    } else if (fav.fav_type === 'applied') {
                                        commit('addJobApplied', fav.notice_id);
                                    }
                                    // console.log('job favs:',state.jobFav);
                                }
                            }
                        }
                        );
                    }).catch((error)=>console.error(error));
            });
            // console.log('in get getadmin :', typeof state.adminFav)
            // dispatch('getPerAdmins');
    },
    async favorite({ commit, state, rootState }, { admission, fav_cat, fav_type }) {
        var url = "https://www.inspirenignite.com/wp-json/compare/v1/setuserfav?" +
            "usr_id=" + rootState.user.uid + "&notice_id=" + admission.id + "&" +
            "fav_category=" + fav_cat + "&" +
            "fav_type=" + fav_type;
        console.log(url);
        axios
            .get(
                url
            )
            .then(() => {
                if (fav_cat === 'admission') {
                    if (fav_type === 'fav') {
                        const index = state.adminFav.indexOf(admission.id);
                        if (index > -1) {
                            // console.log("before", state.adminFav, index);
                            commit('removeAdminFav', index);
                            // console.log("after", state.adminFav);
                        } else {
                            commit('addAdminFav', admission.id)
                        }
                    } else if (fav_type === 'applied') {
                        const index = state.adminApplied.indexOf(admission.id);
                        if (index > -1) {
                            commit('removeAdminApplied', index);
                        } else {
                            commit('addAdminApplied', admission.id);
                        }
                    }
                } else if (fav_cat === 'job') {
                    if (fav_type === 'fav') {
                        console.log('job fav')
                        const index = state.jobFav.indexOf(admission.id);
                        if (index > -1) {
                            commit('removeJobFav', index);
                        } else {
                            commit('addJobFav', admission.id);
                        }
                    } else if (fav_type === 'applied') {
                        const index = state.jobApplied.indexOf(admission.id);
                        if (index > -1) {
                            commit('removeJobApplied', index);
                        } else {
                            commit('addJobApplied', admission.id);
                        }
                    }
                }
            });
            // dispatch('getPerAdmins');
    },
};

const mutations = {
    setAdminNotices: (state, val) => state.admissions = val,
    setJobNotices: (state, val) => state.jobs = val,
    setLoading: (state, val) => state.isLoading = val,
    addAdminFav: (state, url) => state.adminFav.push(url),
    removeAdminFav: (state, index) => state.adminFav.splice(index, 1),
    addAdminApplied: (state, url) => state.adminApplied.push(url),
    removeAdminApplied: (state, index) => state.adminApplied.splice(index, 1),
    addJobFav: (state, url) => state.jobFav.push(url),
    removeJobFav: (state, index) => state.jobFav.splice(index, 1),
    addJobApplied: (state, url) => state.jobApplied.push(url),
    removeJobApplied: (state, index) => state.jobApplied.splice(index, 1),
    // addperAdmins: (state, val) => state.personalAdmins = val,
};


export default {
    state,
    getters,
    actions,
    mutations
}