<template>
  <v-container fluid>
    <div class="tables-basic">
      <h1 class="page-title mt-10 mb-6">Compare Courses</h1>
      <v-form ref="ccmtForm">
        <v-row class="my-6 justify-center wrap" no-gutters>
          <v-col cols="12" sm="9" md="5" lg="4" class="ma-2">
            <v-card class="mx-auto" outlined>
              <v-card-title class="justify-center blue white--text"
                >Select First Course</v-card-title
              >
              <v-card-text>
                <v-autocomplete
                  v-model="clg1"
                  :items="colleges"
                  :loading="isLoading"
                  color="secondary"
                  hide-no-data
                  hide-selected
                  item-text="college_name"
                  item-value="college_code"
                  label="Select Institute"
                  placeholder="Start typing to Search Institute"
                  prepend-icon="mdi-bank"
                  return-object
                  :rules="[rules.required]"
                  @change="cleardata"
                ></v-autocomplete>
                <v-autocomplete
                  v-model="dept1"
                  :items="departments1"
                  :loading="isLoading"
                  color="secondary"
                  hide-no-data
                  hide-selected
                  item-text="dept_name"
                  item-value="dept_code"
                  label="Select Department"
                  placeholder="Start typing to Search Department"
                  prepend-icon="mdi-home-search"
                  return-object
                  :rules="[rules.required]"
                  @change="cleardata"
                ></v-autocomplete>
                <v-autocomplete
                  v-model="prgm1"
                  :items="programs1"
                  :loading="isLoading"
                  color="secondary"
                  hide-no-data
                  hide-selected
                  item-text="program_name"
                  item-value="program_code"
                  label="Select Course"
                  placeholder="Start typing to Search Course"
                  prepend-icon="mdi-database-search"
                  return-object
                  :rules="[rules.required]"
                  @change="cleardata"
                ></v-autocomplete>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="9" md="5" lg="4" class="ma-2">
            <v-card class="mx-auto" outlined>
              <v-card-title class="justify-center blue white--text"
                >Select Second Course</v-card-title
              >
              <v-card-text>
                <v-autocomplete
                  v-model="clg2"
                  :items="colleges"
                  :loading="isLoading"
                  color="secondary"
                  hide-no-data
                  hide-selected
                  item-text="college_name"
                  item-value="college_code"
                  label="Select Institute"
                  placeholder="Start typing to Search Institute"
                  prepend-icon="mdi-bank"
                  return-object
                  :rules="[rules.required]"
                  @change="cleardata"
                ></v-autocomplete>
                <v-autocomplete
                  v-model="dept2"
                  :items="departments2"
                  :loading="isLoading"
                  color="secondary"
                  hide-no-data
                  hide-selected
                  item-text="dept_name"
                  item-value="dept_code"
                  label="Select Department"
                  placeholder="Start typing to Search Department"
                  prepend-icon="mdi-home-search"
                  return-object
                  :rules="[rules.required]"
                  @change="cleardata"
                ></v-autocomplete>
                <v-autocomplete
                  v-model="prgm2"
                  :items="programs2"
                  :loading="isLoading"
                  color="secondary"
                  hide-no-data
                  hide-selected
                  item-text="program_name"
                  item-value="program_code"
                  label="Select Course"
                  placeholder="Start typing to Search Course"
                  prepend-icon="mdi-database-search"
                  return-object
                  :rules="[rules.required]"
                  @change="cleardata"
                ></v-autocomplete>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="8" sm="5" md="3" lg="2">
            <v-autocomplete
              v-model="cat"
              :items="category"
              :loading="isLoading"
              color="success"
              hide-no-data
              hide-selected
              item-text="cat_name"
              item-value="cat_code"
              label="Select Category"
              :rules="[rules.required]"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="2" lg="1">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="!subscriptionInfo" color="secondary" @click="submit">Compare Courses</v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-form>
      <v-row class="mb-6 justify-center wrap" no-gutters>
        <v-col cols="12" sm="9" md="6" lg="4" class="ma-5">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row v-if="!subscriptionInfo" class="justify-center">
        <paymentCard />
      </v-row>
      <v-row wrap class="justify-center" v-else>
        <v-col cols="12" sm="11" lg="10" xl="7" class="ma-5">
          <div v-for="(item, key) in compareYears" :key="key" class="my-2">
            <v-card flat>
              <v-card-title class="justify-center primary--text">
                {{ item }} Cutoff
              </v-card-title>
              <v-data-table
                :headers="getHeaders"
                :items="compareres[item]"
                class="elevation-1"
                hide-default-footer
              ></v-data-table>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { db } from "@/services/firebase";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import paymentCard from "../Paymentcard/PaymentCard";

export default {
  name: "Compare",
  components: {
    paymentCard,
  },
  data() {
    return {
      collegeNameLimit: 60,
      entries: [],
      category: [],
      isLoading: false,
      collegeName: null,
      search: null,
      clg1: null,
      dept1: null,
      prgm1: null,
      clg2: null,
      dept2: null,
      prgm2: null,
      cat: null,
      compareres: null,
      compareYears: null,
      stats: null,
      rules: { required: (value) => !!value || "Required." },
    };
  },
  computed: {
    ...mapGetters(["user", "isProChecked", "subscriptionInfo"]),
    colleges() {
      if (!this.entries.length) {
        // console.log("entities are null");
        return [];
      }
      return this.entries.map((entry) => {
        const college_name =
          entry.college_name.length > this.descriptionLimit
            ? entry.college_name.slice(0, this.descriptionLimit) + "..."
            : entry.college_name;

        return Object.assign({}, entry, { college_name });
      });
    },
    departments1() {
      if (!this.clg1) return [];
      // console.log("Get dept:", this.clg1);
      return this.clg1["dept"].map((entry) => {
        const dept_name =
          entry.dept_name.length > this.descriptionLimit
            ? entry.dept_name.slice(0, this.descriptionLimit) + "..."
            : entry.dept_name;

        return Object.assign({}, entry, { dept_name });
      });
    },
    programs1() {
      if (!this.dept1) return [];
      // console.log("Get dept:", this.dept1);
      return this.dept1["programs"].map((entry) => {
        const program_name =
          entry.program_name.length > this.descriptionLimit
            ? entry.program_name.slice(0, this.descriptionLimit) + "..."
            : entry.program_name;

        return Object.assign({}, entry, { program_name });
      });
    },
    departments2() {
      if (!this.clg2) return [];
      // console.log("Get programs:", this.clg2);
      return this.clg2["dept"].map((entry) => {
        const dept_name =
          entry.dept_name.length > this.descriptionLimit
            ? entry.dept_name.slice(0, this.descriptionLimit) + "..."
            : entry.dept_name;
        return Object.assign({}, entry, { dept_name });
      });
    },
    programs2() {
      if (!this.dept2) return [];
      // console.log("Get programs:", this.dept2);
      return this.dept2["programs"].map((entry) => {
        const program_name =
          entry.program_name.length > this.descriptionLimit
            ? entry.program_name.slice(0, this.descriptionLimit) + "..."
            : entry.program_name;

        return Object.assign({}, entry, { program_name });
      });
    },

    getHeaders() {
      return [
        { text: "Round", value: "round" },
        { text: "Group", value: "group" },
        {
          text: this.clg1["college_name"] + " - " + this.prgm1["program_name"],
          value: "prg1",
        },
        {
          text: this.clg2["college_name"] + " - " + this.prgm2["program_name"],
          value: "prg2",
        },
      ];
    },
  },
  created: async function () {
    // Items have already been loaded
    if (this.entries.length > 0) return;

    // Items have already been requested
    if (this.isLoading) return;
    this.isLoading = true;

    // load data from firebase
    const examRef = db.collection("codes-data").doc("ccmt");
    examRef
      .get()
      .then((doc) => {
        this.entries = doc.data()["colleges"];
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => (this.isLoading = false));

    this.category = [
      {
        cat_name: "Open",
        cat_code: "OC",
      },
      {
        cat_name: "EWS",
        cat_code: "OCEW",
      },
      {
        cat_name: "OBC",
        cat_code: "BC",
      },
      {
        cat_name: "SC",
        cat_code: "SC",
      },
      {
        cat_name: "ST",
        cat_code: "ST",
      },
      
    ];
    // console.log(this.user);
    if (!this.user) {
      await this.getCurrentUser();
    }
    // console.log(this.isProChecked);
    if(!this.isProChecked){
      await this.getSubsUser();
    }
  },
  methods: {
    ...mapActions(["getCurrentUser", "getSubsUser"]),
    cleardata() {
      this.compareres = null;
      this.compareYears = null;
    },
    async submit() {
      if (this.$refs.ccmtForm.validate()) {
        // check if the data already exists in firebase
        var course1 =
          this.clg1.college_code +
          "_" +
          this.dept1.dept_code +
          "_" +
          this.prgm1.program_code;
        var course2 =
          this.clg2.college_code +
          "_" +
          this.dept2.dept_code +
          "_" +
          this.prgm2.program_code;

        var cat_name;
        if (this.cat == "OC") {
          cat_name = "Open";
        } else if (this.cat == "OCEW") {
          cat_name = "EWS";
        } else {
          cat_name = this.cat;
        }

        var title =
          this.clg1["college_name"] +
          " - " +
          this.prgm1["program_name"] +
          " VS " +
          this.clg2["college_name"] +
          " - " +
          this.prgm2["program_name"] +
          " - for " +
          cat_name +
          " Category M.Tech";
        var slugify = require("slugify");
        var slug = slugify(title, { remove: ".", lower: true });
        var url =
          "https://www.inspirenignite.com/wp-json/compare/v1/ccmt?usr_id=" +
          this.user.uid +
          "&clg1=" +
          course1 +
          "&clg2=";
        url = url + course2 + "&cat=" + this.cat;
        var rows = [];
        var newdata = {};
        // console.log(url);
        axios
          .get(url)
          .then((res) => {
            rows = res.data;
            // console.log("rows in:", rows);
            // Make new data table and save results public object
            rows.forEach((row) => {
              if (!newdata != null && newdata[row.year] == null) {
                // create new array
                newdata[row.year] = [];
                // make a new object insert in compareres
                newdata[row.year].push({
                  round: row.round,
                  group: row.group,
                  prg1: row.college == course1 ? row.cutoff : "",
                  prg2: row.college == course2 ? row.cutoff : "",
                });
                // this.compareres.push(newrow);
              } else {
                // console.log('we have data here: ',newdata[row.year])
                var updated = false;
                newdata[row.year].forEach((compyear) => {
                  if (
                    row.round === compyear.round &&
                    row.group === compyear.group
                  ) {
                    row.college === course1
                      ? (compyear["prg1"] = row.cutoff)
                      : (compyear["prg2"] = row.cutoff);
                    updated = true;
                  }
                });
                if (!updated) {
                  newdata[row.year].push({
                    round: row.round,
                    group: row.group,
                    prg1: row.college === course1 ? row.cutoff : "",
                    prg2: row.college === course2 ? row.cutoff : "",
                  });
                  updated = false;
                }
              }
            });
          })
          .catch((error) => {
            console.log(error);
            // this.errored = true;
          })
          .finally(() => {
            this.compareres = newdata;
            this.compareYears = Object.keys(newdata).sort().reverse();
          });
        axios
          .get(
            "https://www.inspirenignite.com/wp-json/cp/v1/compare?title=" +
              title.replace(/ & /, " and ") +
              "&slug=" +
              slug +
              "&course=M.Tech"
          )
          .then((res) => {
            // console.log("stats return: ",res.data);
            this.stats = res.data;
            this.stats.first_compared = this.stats.first_compared.split(" ")[0];
            this.stats.recent_compared = this.stats.recent_compared.split(
              " "
            )[0];
            // this.totalVisits = parseInt(res.data);
          });
      }
    },
  },
};
</script>

<style scoped src="./Compare.scss" lang="scss"></style>
