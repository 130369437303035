<template>
  <v-container fluid>
    <v-row no-gutters class="d-flex justify-space-between mt-10 mb-10">
      <v-col col="6">
        <h1 class="page-title">CCMT Choice Filling</h1>
      </v-col>
    </v-row>
    <v-row class="justify-center mb-10" no-gutters>
      <v-col cols="12" sm="9" lg="5">
        <v-card class="mx-auto" outlined>
          <v-form class="ma-4" ref="form" v-model="valid" lazy-validation>
            <v-autocomplete
              v-model="degree"
              :items="degrees"
              :loading="isLoading"
              color="success"
              hide-no-data
              hide-selected
              item-text="qualifying_degree"
              item-value="qualifying_degree"
              label="Qualifying Degree"
              placeholder="Start typing to Search Degree"
              return-object
              :rules="[(v) => !!v || 'Required Qualifying degree']"
            ></v-autocomplete>

            <v-select
              v-model="paper"
              :items="gateOptions.gatePapers"
              item-text="brName"
              item-value="brCode"
              :rules="[(v) => !!v || 'Branch is required']"
              label="GATE Paper / Branch"
              required
            ></v-select>

            <v-select
              v-model="category"
              :items="gateOptions.categories"
              item-text="category"
              item-value="val"
              :rules="[(v) => !!v || 'Category is required']"
              label="Category"
              required
            ></v-select>

            <div class="text-center">
              <v-btn
                :disabled="!valid || !subscriptionInfo"
                color="success"
                class="mb-6 mt-5 justify-center wrap"
                @click="submit"
              >
                Get CCMT Choices
              </v-btn>
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-6 justify-center wrap" no-gutters>
        <v-col cols="12" sm="9" md="6" lg="4" class="ma-5">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row v-if="!subscriptionInfo" class="justify-center">
        <paymentCard />
      </v-row>
    <v-row class="ma-4" v-if="rankBasedChoice.length">
      <v-tabs grow>
        <v-tabs-slider></v-tabs-slider>
        <v-tab :href="`#tab-rank`"> Last Rank Admitted </v-tab>
        <v-tab :href="`#tab-institute`"> Institute Based Choice </v-tab>
        <v-tab :href="`#tab-course`"> Course Based Choice </v-tab>

        <v-tab-item :value="'tab-rank'">
          <div class="text-center" >
            <v-data-table
              :headers="choiceHeaders"
              :items="rankBasedChoice"
              item-key="index"
              :search="search"
              sort-by="r1_max_avg"
              :sort-desc="true"
              show-expand
              class="elevation-0"
              @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-col cols="12">
                    <v-card class="mx-1 mb-1">
                      <v-card-title class="pa-6 pb-0">
                        <v-row no-gutters class="justify-space-between">
                          <v-col
                            cols="7"
                            sm="4"
                            md="4"
                            lg="5"
                            class="d-flex align-center"
                          >
                            <p>Previous GATE Cutoffs</p>
                          </v-col>
                          <v-col
                            sm="6"
                            md="6"
                            lg="5"
                            class="d-none d-sm-flex align-center"
                          >
                            <v-icon size="18" color="warning"
                              >mdi-circle-medium</v-icon
                            ><span
                              class="card-dark-grey font-weight-regular mr-3"
                              style="font-size: 14px"
                              >Round 1</span
                            >
                            <v-icon size="18" color="primary"
                              >mdi-circle-medium</v-icon
                            ><span
                              class="card-dark-grey font-weight-regular mr-3"
                              style="font-size: 14px"
                              >Round 2</span
                            >
                            <v-icon size="18" color="secondary"
                              >mdi-circle-medium</v-icon
                            ><span
                              class="card-dark-grey font-weight-regular"
                              style="font-size: 14px"
                              >Round 3</span
                            >
                            <v-icon size="18" color="success"
                              >mdi-circle-medium</v-icon
                            ><span
                              class="card-dark-grey font-weight-regular"
                              style="font-size: 14px"
                              >Round 4</span
                            >
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-text class="pa-6">
                        <v-row>
                          <v-col>
                            <CutoffChart :chart-item="item" />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </td>
              </template>
            </v-data-table>
          </div>
        </v-tab-item>

        <v-tab-item :value="'tab-institute'"> 
          <div class="text-center" >
            <v-data-table
              :headers="choiceHeaders"
              :items="rankBasedChoice"
              item-key="index"
              :search="search"
              sort-by="r1_max_avg"
              group-by="institute"
              :sort-desc="true"
              show-expand
              class="elevation-0"
              @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-col cols="12">
                    <v-card class="mx-1 mb-1">
                      <v-card-title class="pa-6 pb-0">
                        <v-row no-gutters class="justify-space-between">
                          <v-col
                            cols="7"
                            sm="4"
                            md="4"
                            lg="5"
                            class="d-flex align-center"
                          >
                            <p>Previous GATE Cutoffs</p>
                          </v-col>
                          <v-col
                            sm="6"
                            md="6"
                            lg="5"
                            class="d-none d-sm-flex align-center"
                          >
                            <v-icon size="18" color="warning"
                              >mdi-circle-medium</v-icon
                            ><span
                              class="card-dark-grey font-weight-regular mr-3"
                              style="font-size: 14px"
                              >Round 1</span
                            >
                            <v-icon size="18" color="primary"
                              >mdi-circle-medium</v-icon
                            ><span
                              class="card-dark-grey font-weight-regular mr-3"
                              style="font-size: 14px"
                              >Round 2</span
                            >
                            <v-icon size="18" color="secondary"
                              >mdi-circle-medium</v-icon
                            ><span
                              class="card-dark-grey font-weight-regular"
                              style="font-size: 14px"
                              >Round 3</span
                            >
                            <v-icon size="18" color="success"
                              >mdi-circle-medium</v-icon
                            ><span
                              class="card-dark-grey font-weight-regular"
                              style="font-size: 14px"
                              >Round 4</span
                            >
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-text class="pa-6">
                        <v-row>
                          <v-col>
                            <CutoffChart :chart-item="item" />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </td>
              </template>
            </v-data-table>
          </div>
        </v-tab-item>

        <v-tab-item :value="'tab-course'"> 
            <v-data-table
              :headers="choiceHeaders"
              :items="rankBasedChoice"
              item-key="index"
              :search="search"
              sort-by="course"
              show-expand
              class="elevation-0"
              @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-col cols="12">
                    <v-card class="mx-1 mb-1">
                      <v-card-title class="pa-6 pb-0">
                        <v-row no-gutters class="justify-space-between">
                          <v-col
                            cols="7"
                            sm="4"
                            md="4"
                            lg="5"
                            class="d-flex align-center"
                          >
                            <p>Previous GATE Cutoffs</p>
                          </v-col>
                          <v-col
                            sm="6"
                            md="6"
                            lg="5"
                            class="d-none d-sm-flex align-center"
                          >
                            <v-icon size="18" color="warning"
                              >mdi-circle-medium</v-icon
                            ><span
                              class="card-dark-grey font-weight-regular mr-3"
                              style="font-size: 14px"
                              >Round 1</span
                            >
                            <v-icon size="18" color="primary"
                              >mdi-circle-medium</v-icon
                            ><span
                              class="card-dark-grey font-weight-regular mr-3"
                              style="font-size: 14px"
                              >Round 2</span
                            >
                            <v-icon size="18" color="secondary"
                              >mdi-circle-medium</v-icon
                            ><span
                              class="card-dark-grey font-weight-regular"
                              style="font-size: 14px"
                              >Round 3</span
                            >
                            <v-icon size="18" color="success"
                              >mdi-circle-medium</v-icon
                            ><span
                              class="card-dark-grey font-weight-regular"
                              style="font-size: 14px"
                              >Round 4</span
                            >
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-text class="pa-6">
                        <v-row>
                          <v-col>
                            <CutoffChart :chart-item="item" />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </td>
              </template>
            </v-data-table>
        </v-tab-item>
      </v-tabs>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import gateOptions from "./formData";
import CutoffChart from "../Cutoffchart/Cutoffchart";
import { mapActions, mapGetters } from "vuex";
import paymentCard from "../Paymentcard/PaymentCard";


export default {
  name: "Choices",
  components: {
    CutoffChart,
    paymentCard
  },
  data: () => ({
    search: "",
    isLoading: true,
    valid: true,
    gateOptions,
    degrees: [],
    degree: "",
    paper: null,
    category: null,
    chioceData: null,
    rankBasedChoice: [],
    choiceHeaders: [
      { text: "Institute", value: "institute" },
      { text: "Department", value: "department" },
      { text: "Programme", value: "course" },
      { text: "Avg. R1 Min", value: "r1_max_avg" },
      { text: "Avg. R2 Min", value: "r2_max_avg" },
      { text: "Avg. R3 Min", value: "r3_max_avg" },
      { text: "Avg. NSR Min", value: "r4_max_avg" },
    ],
    historyHeaders: [
      { text: "Year", value: "year" },
      { text: "Round", value: "round" },
      { text: "Min Score", value: "max_score" },
    ],
  }),
  created: async function () {
    // console.log("in created");
    this.isLoading = true;
    var url = "https://www.inspirenignite.com/wp-json/compare/v1/ccmtdegree";
    axios
      .get(url)
      .then((res) => {
        // console.log("stats return: ",res.data);
        this.degrees = res.data;
      })
      .catch((error) => {
        console.log(error);
        return error;
      })
      .finally(() => {
        this.isLoading = false;
      });
      if (!this.user) {
      await this.getCurrentUser();
    }
    if(!this.isProChecked){
      await this.getSubsUser();
    }
  },
  computed: mapGetters(['user','isProChecked','subscriptionInfo']),
  methods: {
    ...mapActions(['getCurrentUser','getSubsUser']),
    submit() {
      var base_url;
      var url;
      if (this.user === null) {
        this.getCurrentUser();
      }
      // validate form
      this.$refs.form.validate();
      base_url =
        "https://www.inspirenignite.com/wp-json/compare/v1/ccmtchoices?usr_id="+this.user.uid+"&score=1000&";
        // console.log(base_url);
      url =
        base_url +
        "paper=" +
        this.paper +
        "&degree=" +
        this.degree.qualifying_degree +
        "&cat=" +
        this.category;
      // console.log(url);
      // console.log(this.degree.qualifying_degree);
      axios
        .get(url)
        .then((res) => {
          // console.log("stats return: ", res.data);
          this.chioceData = res.data;
          // test rank based choice data
          this.rankData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    rankData() {
      // get the data and create a new one
      this.rankBasedChoice = [];
      var current_course = {};
      var history_data = [];
      var tableIndex = 1;
      var year = [];
      // console.log("input data length: ", this.chioceData.length);
      this.chioceData.forEach((dataPoint) => {
        // make a current object
        if (
          current_course.institute != dataPoint.institute ||
          current_course.department != dataPoint.department ||
          current_course.course != dataPoint.course
        ) {
          // add old datapoint to choice data
          if (Object.keys(current_course).length != 0) {
            // make values for charts
            year.sort();
            var r1 = Array(year.length).fill(0);
            var r2 = Array(year.length).fill(0);
            var r3 = Array(year.length).fill(0);
            var r4 = Array(year.length).fill(0);
            // console.log(year);
            history_data.forEach((dp) => {
              var index = year.indexOf(dp.year);
              if (index < 0) {
                console.log("year not found in history_data");
              }
              if (dp.round == "1") {
                r1[index] = parseInt(dp.max_score);
              } else if (dp.round == "2") {
                r2[index] = parseInt(dp.max_score);
              } else if (dp.round == "3") {
                r3[index] = parseInt(dp.max_score);
              } else if (dp.round == "4") {
                r4[index] = parseInt(dp.max_score);
              }
            });
            current_course.history_data = {
              year: year,
              series: [
                { name: "Round 1", type: "line", data: r1 },
                { name: "Round 2", type: "line", data: r2 },
                { name: "Round 3", type: "line", data: r3 },
                { name: "Round 4", type: "line", data: r4 },
              ],
            };
            this.rankBasedChoice.push(current_course);
            current_course = {};
            history_data = [];
            year = [];
          }
          // console.log(current_course);
          current_course.institute = dataPoint.institute;
          current_course.department = dataPoint.department;
          current_course.course = dataPoint.course;
          current_course.index = tableIndex;
          tableIndex = tableIndex + 1;
        }
        if (dataPoint.round == 1) {
          if (current_course.r1_min_avg != null) {
            current_course.r1_min_avg = Math.round(
              (current_course.r1_min_avg * 1 + dataPoint.min_score * 1) / 2
            );
            current_course.r1_max_avg = Math.round(
              (current_course.r1_max_avg * 1 + dataPoint.max_score * 1) / 2
            );
          } else {
            current_course.r1_min_avg = dataPoint.min_score;
            current_course.r1_max_avg = dataPoint.max_score;
          }
        } else if (dataPoint.round == 2) {
          if (current_course.r2_min_avg != null) {
            current_course.r2_min_avg = Math.round(
              (current_course.r2_min_avg * 1 + dataPoint.min_score * 1) / 2
            );
            current_course.r2_max_avg = Math.round(
              (current_course.r2_max_avg * 1 + dataPoint.max_score * 1) / 2
            );
          } else {
            current_course.r2_min_avg = dataPoint.min_score;
            current_course.r2_max_avg = dataPoint.max_score;
          }
        } else if (dataPoint.round == 3) {
          if (current_course.r3_min_avg != null) {
            current_course.r3_min_avg = Math.round(
              (current_course.r3_min_avg * 1 + dataPoint.min_score * 1) / 2
            );
            current_course.r3_max_avg = Math.round(
              (current_course.r3_max_avg * 1 + dataPoint.max_score * 1) / 2
            );
          } else {
            current_course.r3_min_avg = dataPoint.min_score;
            current_course.r3_max_avg = dataPoint.max_score;
          }
        } else if (dataPoint.round == 4) {
          if (current_course.r4_min_avg != null) {
            current_course.r4_min_avg = Math.round(
              (current_course.r4_min_avg * 1 + dataPoint.min_score * 1) / 2
            );
            current_course.r4_max_avg = Math.round(
              (current_course.r4_max_avg * 1 + dataPoint.max_score * 1) / 2
            );
          } else {
            current_course.r4_min_avg = dataPoint.min_score;
            current_course.r4_max_avg = dataPoint.max_score;
          }
        }
        // console.log("current_data: ", year.indexOf(dataPoint.year));
        if (year.indexOf(dataPoint.year) < 0) {
          year.push(dataPoint.year);
        }
        history_data.push({
          year: dataPoint.year,
          round: dataPoint.round,
          min_score: dataPoint.min_score,
          max_score: dataPoint.max_score,
        });
        // }
        // }
      });
      // add last course datapoint
      // todo: add serieses too
      this.rankBasedChoice.push(current_course);
      // console.log(this.rankBasedChoice);
    },
  },
};
</script>

<style scoped src="./Choices.scss" lang="scss"></style>
