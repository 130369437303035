var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"tables-basic"},[_c('h1',{staticClass:"page-title mt-10 mb-6"},[_vm._v("Admission Notices")]),(_vm.isLoading)?_c('v-container',{staticStyle:{"height":"400px"}},[_c('v-row',{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c('v-col',{staticClass:"subtitle-1 text-center",attrs:{"cols":"12"}},[_vm._v(" Getting latest admission and job notices ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-progress-linear',{attrs:{"color":"warning","indeterminate":"","rounded":"","height":"6"}})],1)],1)],1):_vm._e(),_c('v-expansion-panels',{staticClass:"mb-6",attrs:{"multiple":""}},_vm._l((_vm.admissions),function(admission,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"}},[_vm._v(" "+_vm._s(admission.title)+" ")]),_c('v-expansion-panel-content',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_vm._v(" Fee: "),_c('b',[_vm._v(_vm._s(admission.fee_bc))])]),_c('v-col',[_vm._v(" Last Date to Apply: "),_c('b',[_vm._v(_vm._s(admission.last_data))])])],1),_c('v-row',[_c('v-col',[_vm._v(" Official Link: "),_c('b',[_vm._v(_vm._s(admission.url))])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":_vm.adminFav.includes(admission.id) ? 'success' : ''},on:{"click":function($event){_vm.subscriptionInfo
                    ? _vm.updateFavs(admission, 'admission', 'fav')
                    : _vm.switchOverlay()}}},[_c('v-icon',[_vm._v("mdi-heart")])],1),_c('v-btn',{attrs:{"icon":"","color":_vm.adminApplied.includes(admission.id) ? 'primary' : ''},on:{"click":function($event){_vm.subscriptionInfo
                    ? _vm.updateFavs(admission, 'admission', 'applied')
                    : _vm.switchOverlay()}}},[_c('v-icon',[_vm._v("mdi-check-circle")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-share-variant")])],1)],1),_c('v-overlay',{attrs:{"absolute":_vm.absolute,"opacity":_vm.opacity,"value":_vm.overlay,"color":"#385F73"}},[_c('v-row',{staticClass:"justify-center mb-7"},[_vm._v(" Application Favorite and Applied tracking features are available only to pro memebers. ")]),_c('v-row',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"warning"},on:{"click":function($event){_vm.overlay = false}}},[_vm._v(" Cancel ")]),_c('paymentBtn')],1)],1)],1)],1)],1)}),1)],1),_c('div',{staticClass:"tables-basic"},[_c('h1',{staticClass:"page-title mt-10 mb-6"},[_vm._v("Job Notices")]),_c('v-expansion-panels',{staticClass:"mb-6",attrs:{"multiple":""}},_vm._l((_vm.jobs),function(job,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"}},[_vm._v(" "+_vm._s(job.title)+" ")]),_c('v-expansion-panel-content',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_vm._v(" Fee: "),_c('b',[_vm._v(_vm._s(job.fee_bc))])]),_c('v-col',[_vm._v(" Last Date to Apply: "),_c('b',[_vm._v(_vm._s(job.last_data))])])],1),_c('v-row',[_c('v-col',[_vm._v(" Official Link: "),_c('b',[_vm._v(_vm._s(job.url))])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":_vm.jobFav.includes(job.id) ? 'success' : ''},on:{"click":function($event){_vm.subscriptionInfo
                    ? _vm.updateFavs(job, 'job', 'fav')
                    : _vm.switchOverlay()}}},[_c('v-icon',[_vm._v("mdi-heart")])],1),_c('v-btn',{attrs:{"icon":"","color":_vm.jobApplied.includes(job.id) ? 'primary' : ''},on:{"click":function($event){_vm.subscriptionInfo
                    ? _vm.updateFavs(job, 'job', 'applied')
                    : _vm.switchOverlay()}}},[_c('v-icon',[_vm._v("mdi-check-circle")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-share-variant")])],1)],1),_c('v-overlay',{attrs:{"absolute":_vm.absolute,"opacity":_vm.opacity,"value":_vm.overlay,"color":"#385F73"}},[_c('v-row',{staticClass:"justify-center mb-7"},[_vm._v(" Application Favorite and Applied tracking features are available only to pro memebers. ")]),_c('v-row',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"warning"},on:{"click":function($event){_vm.overlay = false}}},[_vm._v(" Cancel ")]),_c('paymentBtn')],1)],1)],1)],1)],1)}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }