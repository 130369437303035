<template>
  <v-container fluid>
    <div class="dashboard-page">
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <h1 class="page-title">Personal Overview</h1>
      </v-row>
      <v-row class="mb-3">
        <v-col lg="6" sm="8" md="7" cols="12">
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-3">
              <p>Personal Info</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-6 pt-0">
              <v-row no-gutters class="pb-5">
                <v-col cols="12" class="my-auto">
                  <span
                    class="font-weight-medium card-dark-grey"
                    style="font-size: 20px"
                    >{{ user ? user.displayName : "" }}</span
                  >
                </v-col>
              </v-row>
              <v-row no-gutters class="justify-start pb-3">
                <v-col cols="5">
                  <div class="card-light-grey">Membership</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    {{ subscriptionInfo ? "Pro Member" : "Limited" }}
                  </div>
                </v-col>
                <v-col cols="7" class="mt-3" v-if="!subscriptionInfo">
                  <paymentBtn />
                </v-col>
                <v-col cols="4" xl="2" v-if="subscriptionInfo">
                  <div class="card-light-grey">Started</div>
                  <div class="text-h6 font-weight-regular primary--text">
                    {{dateConvert(subscriptionInfo.start)}}
                  </div>
                </v-col>
                <v-col cols="3" xl="2" v-if="subscriptionInfo">
                  <div class="text-right card-light-grey">Ending</div>
                  <div
                    class="text-right text-h6 secondary--text font-weight-regular"
                  >
                    {{dateConvert(subscriptionInfo.end)}}
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="5" sm="6" md="5" cols="12">
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-3">
              <p>Admission Apps</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-6 pt-0">
              <v-row no-gutters class="pb-5">
                <div class="mr-4">
                  <v-icon color="success" class="ml-n2">
                    mdi-circle-medium
                  </v-icon>
                  <span class="card-light-grey">Favorites</span>
                </div>
                <div>
                  <v-icon color="primary"> mdi-circle-medium </v-icon>
                  <span class="card-light-grey">Applied</span>
                </div>
              </v-row>
              <v-row no-gutters class="justify-space-between pb-3">
                <v-col cols="5">
                  <div class="card-light-grey">Total</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    {{ admissions ? admissions.length : 0 }}
                  </div>
                </v-col>
                <v-col cols="3">
                  <div class="card-light-grey">Favorites</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    {{ adminFav ? adminFav.length : 0 }}
                  </div>
                </v-col>
                <v-col cols="4" xl="2">
                  <div class="text-right card-light-grey">Applied</div>
                  <div
                    class="text-right text-h6 card-dark-grey font-weight-regular"
                  >
                    {{ adminApplied ? adminApplied.length : 0 }}
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="5" sm="6" md="5" cols="12">
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-3">
              <p>Job Apps</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-6 pt-0">
              <v-row no-gutters class="pb-5">
                <div class="mr-4">
                  <v-icon color="success" class="ml-n2">
                    mdi-circle-medium
                  </v-icon>
                  <span class="card-light-grey">Favorites</span>
                </div>
                <div>
                  <v-icon color="primary"> mdi-circle-medium </v-icon>
                  <span class="card-light-grey">Applied</span>
                </div>
              </v-row>
              <v-row no-gutters class="justify-space-between pb-3">
                <v-col cols="5">
                  <div class="card-light-grey">Total</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    {{ jobs ? jobs.length : 0 }}
                  </div>
                </v-col>
                <v-col cols="3">
                  <div class="card-light-grey">Favorites</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    {{ jobFav ? jobFav.length : 0 }}
                  </div>
                </v-col>
                <v-col cols="4" xl="2">
                  <div class="text-right card-light-grey">Applied</div>
                  <div
                    class="text-right text-h6 card-dark-grey font-weight-regular"
                  >
                    {{ jobApplied ? jobApplied.length : 0 }}
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="support-requests mx-1 mb-1">
            <v-card-title class="pa-6 pb-0 mb-3">
              <p>Admission Application Status</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-simple-table>
                <template v-slot:default>
                  <thead class="pl-2">
                    <tr>
                      <th class="text-left pa-6">APPLICATION</th>
                      <th class="text-left">FEE</th>
                      <th class="text-left">LAST DATE</th>
                      <th class="text-left">STATUS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in personalAdmins" :key="item.id">
                      <td class="pa-6" width="60%">{{ item.title }}</td>
                      <td>{{ item.fee_oc }}</td>
                      <td>{{ item.last_data }}</td>
                      <td>
                        <v-chip
                          v-if="item.isFav"
                          link
                          color="success"
                          class="ma-2 ml-0"
                        >
                          Favorite
                        </v-chip>
                        <v-chip
                          v-if="item.isApplied"
                          link
                          color="warning"
                          class="ma-2 ml-0"
                        >
                          Applied
                        </v-chip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="support-requests mx-1 mb-1">
            <v-card-title class="pa-6 pb-0">
              <p>Job Application Status</p>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-simple-table>
                <template v-slot:default>
                  <thead class="pl-2">
                    <tr>
                      <th class="text-left pa-6">APPLICATION</th>
                      <th class="text-left">FEE</th>
                      <th class="text-left">LAST DATE</th>
                      <th class="text-left">STATUS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in personalJobs" :key="item.id">
                      <td class="pa-6" width="60%">{{ item.title }}</td>
                      <td>{{ item.fee_oc }}</td>
                      <td>{{ item.last_data }}</td>
                      <td>
                        <v-chip
                          v-if="item.isFav"
                          link
                          color="success"
                          class="ma-2 ml-0"
                        >
                          Favorite
                        </v-chip>
                        <v-chip
                          v-if="item.isApplied"
                          link
                          color="warning"
                          class="ma-2 ml-0"
                        >
                          Applied
                        </v-chip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import mock from "./mock";
import { mapGetters, mapActions } from "vuex";
import paymentBtn from "../PaymentBtn/PaymentBtn";

export default {
  name: "Profile",
  components: {
    paymentBtn,
  },
  data() {
    return {
      mock,
    };
  },
  computed: {
    ...mapGetters([
      "admissions",
      "jobs",
      "isLoading",
      "adminFav",
      "adminApplied",
      "jobFav",
      "jobApplied",
      "user",
      "isProChecked",
      "subscriptionInfo"
    ]),
    personalAdmins: function () {
      // console.log('admissions:', this.admissions);
      var personalNotices = [];
      if (this.adminFav.length > 0) {
        // make admission table
        for (var fav in this.adminFav) {
          var notice = this.admissions.find((o) => o.id === this.adminFav[fav]);
          notice = JSON.parse(JSON.stringify(notice));
          notice.isFav = true;
          // console.log('check each notice:',notice);
          personalNotices.push(notice);
        }
      }
      // console.log("admission table applied: ", personalNotices);
      // console.log("admin applied: ",this.adminApplied);
      if (this.adminApplied.length > 0) {
        // console.log("in admin applied");
        for (var appl in this.adminApplied) {
          notice = personalNotices.find((o, i) => {
            if (o.id === this.adminApplied[appl]) {
              personalNotices[i].isApplied = true;
              return true;
            }
          });
          // console.log(typeof notice);
          if (notice == null) {
            notice = this.admissions.find(
              (o) => o.id === this.adminApplied[appl]
            );
            notice = JSON.parse(JSON.stringify(notice));
            notice.isApplied = true;
            personalNotices.push(notice);
          }
        }
      }
      // console.log(personalNotices);
      return personalNotices;
    },
    personalJobs: function () {
      // console.log('creating personal job table');
      var personaljbs = [];
      var notice;
      // console.log('jobs: ',)
      if (this.jobFav.length > 0) {
        // make admission table
        for (var fav in this.jobFav) {
          notice = this.jobs.find((o) => o.id === this.jobFav[fav]);
          notice = JSON.parse(JSON.stringify(notice));
          notice.isFav = true;
          personaljbs.push(notice);
        }
      }
      if (this.jobApplied.length > 0) {
        for (var appl in this.jobApplied) {
          notice = personaljbs.find((o, i) => {
            if (o.id === this.jobApplied[appl]) {
              personaljbs[i].isApplied = true;
              return true;
            }
          });
          // if the notice is only applied not favorited
          if (notice == null) {
            notice = this.jobs.find((o) => o.id === this.jobApplied[appl]);
            // console.log('only fav:', notice);
            notice = JSON.parse(JSON.stringify(notice));
            notice.isApplied = true;
            personaljbs.push(notice);
          }
        }
      }
      // console.log('job table: ',personaljbs);
      return personaljbs;
    },
  },
  methods: {
    ...mapActions(["getNotices","getCurrentUser","getSubsUser"]),
    dateConvert(epoch){
      const justDate = new Date(parseInt(epoch));
       return justDate.toLocaleString('en-GB').slice(0,10).replaceAll('/','.');
    }
  },
  created: async function () {
    // console.log('user in profile:', this.user.displayName);
    if (!this.user) {
      await this.getCurrentUser();
    }
    if(!this.isProChecked){
      this.getSubsUser();
    }
    if (this.admissions === null) {
      this.getNotices();
    }
  },
};
</script>

<style src="./Profile.scss" lang="scss"/>
