<template>
  <v-container fluid>
    <div class="tables-basic">
      <h1 class="page-title mt-10 mb-6">FAQs</h1>

      <v-container style="height: 400px" v-if="isLoading">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col class="subtitle-1 text-center" cols="12">
            Getting latest admission and job notices
          </v-col>
          <v-col cols="6">
            <v-progress-linear
              color="warning"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>

      <v-expansion-panels multiple class="mb-6">
        <v-expansion-panel v-for="(faq, i) in faqs" :key="i">
          <v-expansion-panel-header expand-icon="mdi-menu-down" class="">
            {{ faq.question }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            {{faq.answer}}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "FAQs",
  data: () => ({
    faqs: null,
    isLoading: true,
  }),
  created: function(){
    var url = "https://www.inspirenignite.com/wp-json/faqs/v1/getfaqs?type=GATE";
    axios
      .get(url)
      .then((res) => {
        // console.log("stats return: ",res.data);
        this.faqs = res.data;
      })
      .catch((error) => {
        console.log(error);
        return error;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

};
</script>

<style scoped src="./Faqs.scss" lang="scss"></style>
