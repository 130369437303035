<template>
  <v-container fluid>
    <div class="tables-basic">
      <h1 class="page-title mt-10 mb-6">Admission Notices</h1>

      <v-container style="height: 400px" v-if="isLoading">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col class="subtitle-1 text-center" cols="12">
            Getting latest admission and job notices
          </v-col>
          <v-col cols="6">
            <v-progress-linear
              color="warning"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>

      <v-expansion-panels multiple class="mb-6">
        <v-expansion-panel v-for="(admission, i) in admissions" :key="i">
          <v-expansion-panel-header expand-icon="mdi-menu-down" class="">
            {{ admission.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card elevation="0">
              <v-card-text>
                <v-row>
                  <v-col>
                    Fee: <b>{{ admission.fee_bc }}</b>
                  </v-col>
                  <v-col>
                    Last Date to Apply:
                    <b>{{ admission.last_data }}</b>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    Official Link: <b>{{ admission.url }}</b>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  icon
                  @click="
                    subscriptionInfo
                      ? updateFavs(admission, 'admission', 'fav')
                      : switchOverlay()
                  "
                  :color="adminFav.includes(admission.id) ? 'success' : ''"
                >
                  <v-icon>mdi-heart</v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="
                    subscriptionInfo
                      ? updateFavs(admission, 'admission', 'applied')
                      : switchOverlay()
                  "
                  :color="adminApplied.includes(admission.id) ? 'primary' : ''"
                >
                  <v-icon>mdi-check-circle</v-icon>
                </v-btn>

                <v-btn icon>
                  <v-icon>mdi-share-variant</v-icon>
                </v-btn>
              </v-card-actions>
              <v-overlay
                :absolute="absolute"
                :opacity="opacity"
                :value="overlay"
                color="#385F73"
              >
                <v-row class="justify-center mb-7">
                  Application Favorite and Applied tracking features are
                  available only to pro memebers.
                </v-row>
                <v-row class="justify-center">
                    <v-btn class="mx-2" color="warning" @click="overlay = false">
                      Cancel
                    </v-btn>
                 
                    <paymentBtn />
                </v-row>
              </v-overlay>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div class="tables-basic">
      <h1 class="page-title mt-10 mb-6">Job Notices</h1>
      <v-expansion-panels multiple class="mb-6">
        <v-expansion-panel v-for="(job, i) in jobs" :key="i">
          <v-expansion-panel-header expand-icon="mdi-menu-down" class="">
            {{ job.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card elevation="0">
              <v-card-text>
                <v-row>
                  <v-col>
                    Fee: <b>{{ job.fee_bc }}</b>
                  </v-col>
                  <v-col>
                    Last Date to Apply:
                    <b>{{ job.last_data }}</b>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    Official Link: <b>{{ job.url }}</b>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  icon
                  @click="
                    subscriptionInfo
                      ? updateFavs(job, 'job', 'fav')
                      : switchOverlay()
                  "
                  :color="jobFav.includes(job.id) ? 'success' : ''"
                >
                  <v-icon>mdi-heart</v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="
                    subscriptionInfo
                      ? updateFavs(job, 'job', 'applied')
                      : switchOverlay()
                  "
                  :color="jobApplied.includes(job.id) ? 'primary' : ''"
                >
                  <v-icon>mdi-check-circle</v-icon>
                </v-btn>

                <v-btn icon>
                  <v-icon>mdi-share-variant</v-icon>
                </v-btn>
              </v-card-actions>
              <v-overlay
                :absolute="absolute"
                :opacity="opacity"
                :value="overlay"
                color="#385F73"
              >
                <v-row class="justify-center mb-7">
                  Application Favorite and Applied tracking features are
                  available only to pro memebers.
                </v-row>
                <v-row class="justify-center">
                    <v-btn class="mx-2" color="warning" @click="overlay = false">
                      Cancel
                    </v-btn>
                 
                    <paymentBtn />
                </v-row>
              </v-overlay>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import paymentBtn from "../PaymentBtn/PaymentBtn"

export default {
  name: "Admissions",
  components: {
    paymentBtn,
  },
  data: () => ({
    absolute: true,
    opacity: 1,
    overlay: false,
  }),
  computed: mapGetters([
    "admissions",
    "jobs",
    "isLoading",
    "adminFav",
    "adminApplied",
    "jobFav",
    "jobApplied",
    "user",
    "isProChecked",
    "subscriptionInfo",
  ]),
  methods: {
    ...mapActions(["getNotices", "favorite", "getCurrentUser", "getSubsUser"]),
    updateFavs(admission, fav_cat, fav_type) {
      // console.log("fav is called");
      this.favorite({ admission, fav_cat, fav_type });
    },
    switchOverlay() {
      // console.log('we are in overlay');
      this.overlay = true;
    },
  },
  created: async function () {
    if (this.admissions === null) {
      this.getNotices();
    }
    if (!this.user) {
      await this.getCurrentUser();
    }
    if (!this.isProChecked) {
      await this.getSubsUser();
    }
  },
};
</script>

<style scoped src="./Admissions.scss" lang="scss"></style>
