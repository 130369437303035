import Vue from 'vue';
import Vuex from 'vuex';
import notices from './modules/notices';
import drawer from './modules/drawer';
import gauth from './modules/auth';

Vue.use(Vuex);

export default new Vuex.Store({
  modules:{
    notices,
    drawer,
    gauth,
  },
});
