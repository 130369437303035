<template>
  <v-container fluid>
    <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
      <v-col col="6">
        <h1 class="page-title">GATE Cutoff</h1>
      </v-col>
      <v-col sm="5" md="4" lg="3" class="page-title">
        <v-select
          v-model="formData.institute"
          :items="formData.institutes"
          item-text="inst"
          item-value="abbr"
          label="Cutoff for IITs or NITs"
          dense
        ></v-select>
      </v-col>
    </v-row>

    <v-row class="justify-center" no-gutters>
      <v-col cols="12" sm="9" lg="6">
        <v-card class="mx-auto" outlined>
          <v-form class="ma-4" ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="score"
              :rules="scoreRules"
              label="Score"
              type="number"
              required
            ></v-text-field>
            <v-select
              v-model="paper"
              :items="formData.gatePapers"
              item-text="brName"
              item-value="brCode"
              :rules="[(v) => !!v || 'Item is required']"
              label="GATE Paper / Branch"
              required
            ></v-select>

            <v-select
              v-model="category"
              :items="formData.categories"
              item-text="category"
              item-value="val"
              :rules="[(v) => !!v || 'Item is required']"
              label="Category"
              required
            ></v-select>

            <v-select
              v-if="formData.institute === 'CCMT'"
              v-model="round"
              :items="formData.rounds"
              :rules="[(v) => !!v || 'Item is required']"
              label="Round"
              required
            ></v-select>

            <v-select
              v-model="year"
              :items="formData.years"
              :rules="[(v) => !!v || 'Item is required']"
              label="Year"
              required
            ></v-select>
            <div class="text-center">
              <v-btn
                :disabled="!valid"
                color="success"
                class="mb-6 mt-5 justify-center wrap"
                @click="submit"
              >
                Get cutoffs
              </v-btn>
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="cutoff.message" class="mt-10">
      <paymentCard />
    </div>
    <v-card v-else-if="cutoff.length" class="mt-10">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="cutoffHeader"
        :items="cutoff"
        :search="search"
      ></v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import paymentCard from "../Paymentcard/PaymentCard";
import axios from "axios";
import formData from "./formData";
import {mapActions, mapGetters} from 'vuex'

export default {
  name: "Cutoff",
  components: {
    paymentCard,
  },
  data: () => ({
    formData,
    search: "",
    valid: true,
    score: null,
    scoreRules: [
      (v) => !!v || "Score is required",
      (v) => (v && v > 0 && v <= 1000) || "GATE score must be between 0 & 1000",
    ],
    paper: null,
    category: null,
    year: null,
    round: null,
    cutoff: [],
    cutoffHeader: [],
  }),
  computed: {
      ...mapGetters(["user", "loginError"]),
  },
  created:function (){
      if (this.user === null) {
        this.getCurrentUser();
      }
    },
  methods: {
    ...mapActions(['getCurrentUser' ]),
    submit() {
      var base_url;
      var url;
      // validate form
      this.$refs.form.validate();

      if (formData.institute === "CCMT") {
        base_url = "https://www.inspirenignite.com/wp-json/cutoff/v1/ccmt?";
        // add this to url score=1000&category=SC&branch=CS&round=1&year=2019
        // console.log(this.user);
        url =
          base_url +
          "usr_id=" +
          this.user.uid+
          "&score=" +
          this.score +
          "&category=" +
          this.category +
          "&branch=" +
          this.paper +
          "&round=" +
          this.round +
          "&year=" +
          this.year;
        // console.log(url);
        this.cutoffHeader = JSON.parse(JSON.stringify(formData.nitHeaders));
      } else {
        base_url = "https://www.inspirenignite.com/wp-json/cutoff/v1/iit?";
        // add this to url score=1000&category=SC&branch=CS&round=1&year=2019
        url =
          base_url +
          "usr_id=" +
          this.user.uid+
          "&score=" +
          this.score +
          "&category=" +
          this.category +
          "&branch=" +
          this.paper +
          "&year=" +
          this.year;
        // https://www.inspirenignite.com/wp-json/compare/v1/colleges?score=1000&category=SC&branch=CS&round=1&year=2019
        this.cutoffHeader = JSON.parse(JSON.stringify(formData.nitHeaders));
        // console.log(this.cutoffHeader);
        this.cutoffHeader.splice(this.cutoffHeader.length - 1, 1);
        // console.log(this.cutoffHeader);
      }

      axios
        .get(url)
        .then((res) => {
          // console.log("stats return: ", url);
          this.cutoff = res.data;
          // this.totalVisits = parseInt(res.data);
        })
        .catch((error) => {
          console.log(error);
          console.log(url);
          // this.errored = true;
        });
    },
  },
};
</script>

<style scoped src="./Cutoff.scss" lang="scss"></style>
