import config from '@/config';

export default {
  options: {
    chart: {
      width: '100%',
      toolbar: {
        show: false
      },
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        }
      },
      yaxis: {
        lines: {
          show: false,
        },
      }
    },
    markers: {
      size: 5
    },
    stroke: {
      width: 3,
      curve: 'smooth',
    },
    fill: {
      type: 'linear',
    },
    legend: {
      show: false

    },
    colors: [config.light.warning, config.light.primary, config.light.secondary, config.light.success],
    yaxis: {
      axisBorder: {
        show: true,
        color: '#B9B9B980'
      },
      labels: {
        style: {
          colors: ['#B9B9B980', '#B9B9B980', '#B9B9B980', '#B9B9B980', '#B9B9B980', '#B9B9B980', '#B9B9B980', '#B9B9B980'],
          fontSize: '12px',
        },
      },
      tickAmount: 5
    },
    xaxis: {
      categories: [],
      labels: {
        style: {
          fontSize: '12px',
          colors: ['#B9B9B980', '#B9B9B980', '#B9B9B980', '#B9B9B980', '#B9B9B980', '#B9B9B980', '#B9B9B980', '#B9B9B980', '#B9B9B980', '#B9B9B980', '#B9B9B980', '#B9B9B980', '#B9B9B980', '#B9B9B980'],
        }
      },
      tickAmount: 10,
    }
  },
}