export default {
    institute: 'CCMT',
        institutes: [
          { inst: 'IIT GATE Cutoff', abbr: 'IIT' },
          { inst: 'NIT & Other Cutoffs', abbr: 'CCMT' },
        ],
    years:[
        '2022',
        '2021',
        '2020',
        '2019',
        '2018',
        '2017',
        '2016',
        '2015',
        '2014',
        '2013'
    ],
    rounds:[
        '1',
        '2',
        '3',
        '4'    
    ],
    gatePapers: [
        {brCode: 'AE', brName: 'Aerospace Engineering'},
        {brCode: 'AG', brName: 'Agricultural Engineering'},
        {brCode: 'AR', brName: 'Architecture and Planning'},
        {brCode: 'BM' ,brName:'Biomedical Engineering'},
        {brCode: 'BT', brName: 'Biotechnology'},
        {brCode: 'CE', brName: 'Civil Engineering'},
        {brCode: 'CH', brName: 'Chemical Engineering'},
        {brCode: 'CS', brName: 'Computer Science and Information Technology'},
        {brCode: 'CY', brName: 'Chemistry'},
        {brCode: 'EC', brName: 'Electronics and Communication Engineering'},
        {brCode: 'EE', brName: 'Electrical Engineering'},
        {brCode: 'ES', brName: 'Environmental Science & Engineering'},
        {brCode: 'EY', brName: 'Ecology and Evolution'},
        {brCode: 'GE', brName: 'Geomatics Engineering'}, 
        {brCode: 'GG', brName: 'Geology and Geophysics'},
        {brCode: 'IN', brName: 'Instrumentation Engineering'},
        {brCode: 'MA', brName: 'Mathematics'},
        {brCode: 'ME', brName: 'Mechanical Engineering'},
        {brCode: 'MN', brName: 'Mining Engineering'},
        {brCode: 'MT', brName: 'Metallurgical Engineering'},
        {brCode: 'MN', brName: 'Naval Architecture and Marine Engineering'},
        {brCode: 'PE', brName: 'Petroleum Engineering'},
        {brCode: 'PH', brName: 'Physics'},
        {brCode: 'PI', brName: 'Production and Industrial Engineering'},
        {brCode: 'ST', brName: 'Statistics'},
        {brCode: 'TF', brName: 'Textile Engineering and Fibre Science'},
        {brCode: 'XE', brName: 'Engineering Sciences'},
        {brCode: 'XH', brName: 'Humanities & Social Sciences'},
        {brCode: 'XL', brName: 'Life Sciences'}
    ],
    categories: [
        {category:'Open', val:'OC'},
        {category:'EWS', val:'EW'},
        {category:'OBC', val:'BC'},
        {category:'SC', val:'SC'},
        {category:'ST', val:'ST'}
    ],
    nitHeaders: [
        { text: 'Institute', value: 'Institute', },
        { text: 'Department', value: 'Department' },
        { text: 'Programme', value: 'Programme' },
        { text: 'GATE cutoff', value: 'cutoff' },
        { text: 'Group', value: 'Group_a' },
      ],
    
}
