import Vue from 'vue'
import App from './App.vue'
import router from './Routes'
import store from './store/index'
import vuetify from './plugins/vuetify'
import * as VueGoogleMaps from 'vue2-google-maps';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import firebase from 'firebase/app';
import 'firebase/auth';
import './services/firebase';

Vue.use(Toast);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyB7OXmzfQYua_1LEhRdqsoYzyJOPh9hGLg',
  },
});

Vue.config.productionTip = false

let app;
firebase.auth().onAuthStateChanged((user)=>{
  store.state.user = user;
  // console.log('data on store on refresh: ',store.state.user);
  if (!app){
    new Vue({
      vuetify,
      router,
      render: h => h(App), store
    }).$mount('#app')
  }
})

