<template>
  <v-container fluid>
    <div class="dashboard-page">
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <h1 class="page-title">Exam Overview</h1>
            <paymentBtn v-if="!subscriptionInfo" />
      </v-row>
      <v-row>
        <v-col lg="4" sm="6" md="5" cols="12">
          <v-card class="mx-1 mb-1" v-if="total_seats.iits">
            <v-card-title class="pa-6 pb-3">
              <p>GATE {{ gateOptions.years[gateOptions.years.length-1]}} Registrations</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-6 pt-0" v-if="totalStats.history_data">
              <v-row no-gutters class="pb-5">
                <v-col class="my-auto">
                  <span
                    class="font-weight-medium card-dark-grey"
                    style="font-size: 24px"
                    >{{ totalStats.history_data.series[0].data[totalStats.history_data.series[0].data.length-1] }}</span
                  >
                </v-col>
              </v-row>
              <v-row no-gutters class="justify-space-between pb-3">
                <v-col cols="5">
                  <div class="card-light-grey">Registrations</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    {{ totalStats.history_data.series[0].data[totalStats.history_data.series[0].data.length-1] }}
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="card-light-grey">Appeared</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    {{ totalStats.history_data.series[1].data[totalStats.history_data.series[1].data.length-1] }}
                  </div>
                </v-col>
                <v-col cols="3" xl="2">
                  <div class="text-right card-light-grey">Rate</div>
                  <div
                    class="text-right text-h6 card-dark-grey font-weight-regular"
                  >
                    {{
                      Number(
                        (totalStats.history_data.series[1].data[totalStats.history_data.series[1].data.length-1] /
                          totalStats.history_data.series[0].data[totalStats.history_data.series[0].data.length-1]) *
                          100.0
                      ).toFixed(2)
                    }}%
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="4" sm="6" md="5" cols="12" >
          <v-card class="mx-1 mb-1" v-if="total_seats.iits">
            <v-card-title class="pa-6 pb-3">
              <p>IIT M.Tech Seats</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-6 pt-0">
              <v-row no-gutters class="pb-5">
                <v-col class="my-auto">
                  <span
                    class="font-weight-medium card-dark-grey"
                    style="font-size: 24px"
                    >{{ total_seats.iits.open +
                      total_seats.iits.ews +
                      total_seats.iits.bc +
                      total_seats.iits.sc +
                      total_seats.iits.st +
                      total_seats.iits.pwd}}</span
                  >
                </v-col>
              </v-row>
              <v-row no-gutters class="justify-space-between pb-3">
                <v-col cols="5">
                  <div class="card-light-grey">OC</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    {{ total_seats.iits.open }}
                  </div>
                </v-col>
                <v-col cols="3">
                  <div class="card-light-grey">EWS</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    {{ total_seats.iits.ews }}
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="text-right card-light-grey">BC</div>
                  <div
                    class="text-right text-h6 card-dark-grey font-weight-regular"
                  >
                    {{ total_seats.iits.bc }}
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters class="justify-space-between pb-3">
                <v-col cols="5">
                  <div class="card-light-grey">SC</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    {{ total_seats.iits.sc }}
                  </div>
                </v-col>
                <v-col cols="3">
                  <div class="card-light-grey">ST</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    {{ total_seats.iits.st }}
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="text-right card-light-grey">PwD</div>
                  <div
                    class="text-right text-h6 card-dark-grey font-weight-regular"
                  >
                    {{ total_seats.iits.pwd }}
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="4" sm="6" md="5" cols="12">
          <v-card class="mx-1 mb-1" v-if="total_seats.nits">
            <v-card-title class="pa-6 pb-3">
              <p>NIT, IIIT Seats</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-6 pt-0">
              <v-row no-gutters class="pb-5">
                <v-col class="my-auto">
                  <span
                    class="font-weight-medium card-dark-grey"
                    style="font-size: 24px"
                    >{{
                      total_seats.nits.open +
                      total_seats.nits.ews +
                      total_seats.nits.bc +
                      total_seats.nits.sc +
                      total_seats.nits.st +
                      total_seats.nits.pwd
                    }}</span
                  >
                </v-col>
              </v-row>
              <v-row no-gutters class="justify-space-between pb-3">
                <v-col cols="5">
                  <div class="card-light-grey">OC</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    {{ total_seats.nits.open }}
                  </div>
                </v-col>
                <v-col cols="3">
                  <div class="card-light-grey">EWS</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    {{ total_seats.nits.ews }}
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="text-right card-light-grey">BC</div>
                  <div
                    class="text-right text-h6 card-dark-grey font-weight-regular"
                  >
                    {{ total_seats.nits.bc }}
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters class="justify-space-between pb-3">
                <v-col cols="5">
                  <div class="card-light-grey">SC</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    {{ total_seats.nits.sc }}
                  </div>
                </v-col>
                <v-col cols="3">
                  <div class="card-light-grey">ST</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    {{ total_seats.nits.st }}
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="text-right card-light-grey">PwD</div>
                  <div
                    class="text-right text-h6 card-dark-grey font-weight-regular"
                  >
                    {{ total_seats.nits.pwd }}
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-0">
              <v-row no-gutters class="justify-space-between">
                <v-col
                  cols="7"
                  sm="4"
                  md="4"
                  lg="3"
                  class="d-flex align-center"
                >
                  <p>GATE Stats</p>
                </v-col>
                <v-col
                  sm="4"
                  md="4"
                  lg="3"
                  class="d-none d-sm-flex align-center"
                >
                  <v-icon size="18" color="warning">mdi-circle-medium</v-icon
                  ><span
                    class="card-dark-grey font-weight-regular mr-3"
                    style="font-size: 18px"
                    >Registered</span
                  >
                  <v-icon size="18" color="primary">mdi-circle-medium</v-icon
                  ><span
                    class="card-dark-grey font-weight-regular mr-3"
                    style="font-size: 18px"
                    >Appeared</span
                  >
                </v-col>
                <v-col cols="5" sm="4" md="4" lg="3" offset-lg="1">
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-select
                        class="main-chart-select"
                        v-model="mainApexAreaSelect"
                        v-bind="attrs"
                        v-on="on"
                        v-on:change="getOptions"
                        dense
                        flat
                        single-line
                        hide-details
                        :items="allPapers"
                        item-text="brName"
                        item-value="brCode"
                        outlined
                      ></v-select>
                    </template>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pa-6">
              <v-row>
                <v-col v-if="currentChart.history_data">
                  <StatsChart :chart-item="currentChart" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="support-requests mx-1 mb-1">
            <v-card-title class="pa-6 pb-0">
              <v-row no-gutters class="mb-10 justify-space-between">
                <v-col cols="12" sm="6" md="6" lg="5">
                  <v-autocomplete
                    v-model="college"
                    :items="colleges"
                    color="success"
                    label="Select Institute"
                    placeholder="Start typing to Search Institute"
                    prepend-icon="mdi-bank"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="5" offset-lg="1" v-if="college">
                  <v-select
                    v-model="department"
                    :items="departments"
                    color="success"
                    label="Select Department"
                    placeholder="Start typing to Search Department"
                    prepend-icon="mdi-bank"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row no-gutters class="justify-space-between mb-7">
                <v-col
                  cols="12"
                  class="d-flex align-center"
                >
                  <p>M.Tech Seats in {{college}}, {{department}}</p>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pa-0" v-if="college && department && filteredSeats">
              <v-simple-table>
                <template v-slot:default>
                  <thead class="pl-2">
                    <tr>
                      <th class="text-left pa-6">Program Name</th>
                      <th class="text-left">Open</th>
                      <th class="text-left">EWS</th>
                      <th class="text-left">BC</th>
                      <th class="text-left">SC</th>
                      <th class="text-left">ST</th>
                      <th class="text-left">PWD</th>
                      <th class="text-left">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, key) in filteredSeats" :key="key">
                      <td class="pa-6">{{ item.course_name }}</td>
                      <td>{{ item.open }}</td>
                      <td>{{ item.ews }}</td>
                      <td>{{ item.bc }}</td>
                      <td>{{ item.sc }}</td>
                      <td>{{ item.st }}</td>
                      <td>{{ item.pwd }}</td>
                      <td>
                        <v-chip link color="success" class="ma-2 ml-0">
                          {{(item.open*1)+(item.ews*1)+(item.bc*1)+(item.sc*1)+(item.st*1)+(item.pwd*1)}}
                        </v-chip>
                      </td>
                      </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import mock from "./mock";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import StatsChart from "../Cutoffchart/Cutoffchart";
import paymentBtn from "../PaymentBtn/PaymentBtn";
import gateOptions from "./formData";

export default {
  name: "Dashboard",
  components: {
    paymentBtn,
    StatsChart,
  },
  data() {
    return {
      mock,
      gateOptions,
      apexLoading: false,
      mainApexAreaSelect: "total",
      gateStats: [],
      currentChart: {},
      totalStats: {},
      seats: [],
      total_seats: {},
      college: 'IIT Delhi',
      department: 'Computer Science & Automation',
      allPapers: null,
    };
  },
  computed: {
    ...mapGetters(['user','isProChecked','subscriptionInfo']),
    colleges() {
      if (!this.seats.length) {
        // console.log("entities are null");
        return [];
      }
      // console.log(this.seats);
      return this.seats.map((entry) => {
        // console.log(entry.inst_name);
        return entry.inst_name;
      });
    },
    departments() {
      let depts = [];
      if (!this.college) {
        console.log("departments are null");
        return depts;
      }
      // console.log(this.colleges);
      this.seats.forEach((entry) => {
        if (entry.inst_name === this.college) {
          // console.log(entry.dept_name);
          depts.push(entry.dept_name);
          // return entry.dept_name;
        }
      });
      return depts;
    },
    filteredSeats() {
      // console.log(this.seats)
      return this.seats.filter((i) => {
        return (i.inst_name ===this.college) && (i.dept_name === this.department);
      })
    }
  },
  methods: {
    ...mapActions(['setLoginUser','getCurrentUser','getSubsUser']),
    addStats(year, history_data, currentpaper) {
      // console.log('stats:', history_data);
      // if (currentpaper == 'AE'){
      //   console.log(year);
      //   console.log(history_data);
      // }
      year.sort();
      var registered = Array(year.length).fill(0);
      var appeared = Array(year.length).fill(0);
      history_data.forEach((dp) => {
        var index = year.indexOf(dp.year);
        if (index < 0) {
          // console.log(dp.year, year);
          console.log("year not found in history_data");
        }
        registered[index] = dp.applied;
        appeared[index] = dp.appeared;
        // console.log(this.gateStats);
      });
      this.gateStats.push({
        paper: currentpaper,
        history_data: {
          year: year,
          series: [
            { name: "Registered", type: "line", data: registered },
            { name: "Appeared", type: "line", data: appeared },
          ],
        },
      });
    },
    getOptions() {
      if (this.mainApexAreaSelect === "total") {
        this.currentChart = this.totalStats;
      } else {
        var index = this.gateStats.findIndex(
          (paperStat) => paperStat.paper === this.mainApexAreaSelect
        );
        this.currentChart = this.gateStats[index];
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.apexLoading = true;
    });
  },
  created: async function () {
    this.allPapers = this.cutoffHeader = JSON.parse(JSON.stringify(this.gateOptions.gatePapers));
    this.allPapers.unshift({
      brCode: "total",
      brName: "Total Students",
    });
    axios
      .get("https://www.inspirenignite.com/wp-json/compare/v1/gatestats")
      .then((res) => {
        // console.log("stats return: ", res.data);
        // make a list of total students
        // make indvidual serieses
        this.gateStats = [];
        var year = [];
        var currentpaper = "";
        var history_data = [];
        var total_year = gateOptions.years.sort();
        // total_year;
        // [
        //   "2013",
        //   "2014",
        //   "2015",
        //   "2016",
        //   "2017",
        //   "2018",
        //   "2019",
        //   "2020",
        // ];
        var total_reg = Array(total_year.length).fill(0);
        var total_app = Array(total_year.length).fill(0);
        res.data.forEach((element) => {
          if (currentpaper != element.paper) {
            // add
            if (currentpaper != "") {
              // console.log(year);
              this.addStats(year, history_data, currentpaper);
            }
            currentpaper = element.paper;
            year = [];
            history_data = [];
          }
          if (year.indexOf(element.year) < 0) {
            year.push(element.year);
          }
          history_data.push(element);

          // add data to total data
          let total_index = total_year.indexOf(element.year);
          console.log('adding data :',total_reg[total_index],  element.applied, total_index);
          total_reg[total_index] =
            total_reg[total_index] + parseInt(element.applied);
          total_app[total_index] =
            total_app[total_index] + parseInt(element.appeared);
        });
        this.totalStats = {
          paper: "total Stats",
          history_data: {
            year: total_year,
            series: [
              { name: "Registered", type: "line", data: total_reg },
              { name: "Appeared", type: "line", data: total_app },
            ],
          },
        };
        this.currentChart = this.totalStats;
        this.addStats(year, history_data, currentpaper);
        // console.log('first chart: ', this.currentChart);
      })
      .catch((error) => {
        console.log(error);
      });
    // get seat information and process it
    axios
      .get("https://www.inspirenignite.com/wp-json/compare/v1/gateseats")
      .then((res) => {
        this.seats = res.data;
        // console.log("seats here: ", this.seats);
        var nit_seats = {
          open: 0,
          ews: 0,
          bc: 0,
          sc: 0,
          st: 0,
          pwd: 0,
        };
        var iit_seats = {
          open: 0,
          ews: 0,
          bc: 0,
          sc: 0,
          st: 0,
          pwd: 0,
        };
        res.data.forEach((element) => {
          if (
            element.inst_code.split("-")[0] !== "iit" &&
            element.inst_code.split("-")[0] !== "iisc"
          ) {
            // console.log(nit_seats.open,element.inst_code);
            nit_seats.open = nit_seats.open + parseInt(element.open);
            nit_seats.ews = nit_seats.ews + parseInt(element.ews);
            nit_seats.bc = nit_seats.bc + parseInt(element.bc);
            nit_seats.sc = nit_seats.sc + parseInt(element.sc);
            nit_seats.st = nit_seats.st + parseInt(element.st);
            nit_seats.pwd = nit_seats.pwd + parseInt(element.pwd);
          } else {
            iit_seats.open = iit_seats.open + parseInt(element.open);
            iit_seats.ews = iit_seats.ews + parseInt(element.ews);
            iit_seats.bc = iit_seats.bc + parseInt(element.bc);
            iit_seats.sc = iit_seats.sc + parseInt(element.sc);
            iit_seats.st = iit_seats.st + parseInt(element.st);
            iit_seats.pwd = iit_seats.pwd + parseInt(element.pwd);
          }
        });
        // console.log(nit_seats);
        this.total_seats = {
          nits: nit_seats,
          iits: iit_seats,
        };
      })
      .catch((error) => console.error(error));
    // prepare the the json for

    if (!this.user) {
      await this.getCurrentUser();
    }
    if(!this.isProChecked){
      await this.getSubsUser();
    }
  },
};
</script>

<style src="./Dashboard.scss" lang="scss"/>
