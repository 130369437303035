<template>
  <v-app>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="7" class="main-part d-none d-md-none d-lg-flex">
          <div class="d-flex">
            <v-img src="@/assets/logo.svg" contain></v-img>
            <p>COLLEGEPREDICTOR.AI</p>
          </div>
        </v-col>
        <v-col cols="12" lg="5" class="login-part d-flex align-center justify-center">
          <v-row no-gutters>
            <v-col cols="12" class="login-part d-flex align-center justify-center flex-column">
              <div class="login-wrapper">
                      <v-container>
                        <v-row class="flex-column">
                          <v-col>
                            <p class="login-slogan display-2 text-center font-weight-medium my-10 mb-15">Good Morning, user</p>
                            <v-btn height="45" block color="white" elevation="0" class="google text-capitalize"
                            @click="googleLogin">
                              <v-img src="@/assets/google.svg" max-width="30" class="mr-4"></v-img>
                              Sign in with Google</v-btn>
                          </v-col>
                          <v-col cols="12" class="d-flex align-center my-8">
                            <v-divider></v-divider>
                            <span class="px-5"> or </span>
                            <v-divider></v-divider>
                          </v-col>
                          <v-col>
                            <v-btn height="45" block color="white" elevation="0" class="google text-capitalize"
                            @click="facebookLogin">
                              <v-icon max-width="45" class="mr-4" color="#4267B2" size="38px">mdi-facebook</v-icon>
                              Sign in with Facebook</v-btn>
                          </v-col>
                          
                        </v-row>
                      </v-container>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-footer>
                <div class="primary--text">© 2012-2021 <a href="https://gate.collegepredictor.ai/" class="text-decoration-none">InI Labs Inc.</a>, All rights reserved.</div>
              </v-footer>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import firebase from 'firebase/app';
import 'firebase/auth';

  export default {
    name: 'Login',
    data() {
      return {
        email: 'admin@flatlogic.com',
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+/.test(v) || 'E-mail must be valid',
        ],
        createFullName: 'John Smith',
        createEmail: 'john@flatlogic.com',
        createPassword: '123456',
        password: '123456',
        passRules: [
          v => !!v || 'Password is required',
          v => v.length >= 6 || 'Min 6 characters'
        ]
      }
    },
    computed: mapGetters([
    "user",
    "loginError",
  ]),
    methods: {
      ...mapActions(['setLoginUser']),
      ...mapMutations(['setUer']),
      login(){
        window.localStorage.setItem('authenticated', true);
        this.$router.push('/dashboard');
      },
      googleLogin(){
        var provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth()
            .signInWithPopup(provider)
            .then((res) => {
              console.log(res.user);
              this.setLoginUser(res.user);
              this.$router.push('/dashboard');
            }).catch((error) => {
                console.log('signin error', error);
                // this.setError(error);
            });
      },
      facebookLogin(){
        var provider = new firebase.auth.FacebookAuthProvider();
        firebase.auth()
            .signInWithPopup(provider)
            .then((res) => {
              console.log(res.user);
              this.setLoginUser(res.user);
              this.$router.push('/dashboard');
            }).catch((error) => {
                console.log('signin error', error);
                // this.setError(error);
            });
      }
    },
    created() {
      if (window.localStorage.getItem('authenticated') === 'true') {
        this.$router.push('/dashboard');
      }
    }
  }




</script>

<style src="./Login.scss" lang="scss"/>
