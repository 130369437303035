import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';

  // Your web app's Firebase configuration
  var firebaseConfig = {
    apiKey: "AIzaSyBnXrVnEIgbJNYG-O0sQ9gM5nIUZaYzsag",
    authDomain: "collegepredictor-ai.firebaseapp.com",
    databaseURL: "https://collegepredictor-ai.firebaseio.com",
    projectId: "collegepredictor-ai",
    storageBucket: "collegepredictor-ai.appspot.com",
    messagingSenderId: "325827241834",
    appId: "1:325827241834:web:c385a3052eb850e100a713",
    measurementId: "G-NQSKHZ6FS0"
  };
  // Initialize Firebase
  !firebase.apps.length ? firebase.initializeApp(firebaseConfig):'';
  // firebase.analytics();
  export const db = firebase.firestore();
  // db.settings({timestampsInSnapshots:true});
  // export default db;