<template>
  <v-container fluid>
    <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
      <v-col col="6">
        <h1 class="page-title">GATE Score Predictor</h1>
      </v-col>
    </v-row>
    <v-row class="justify-center" no-gutters>
      <v-col cols="12" sm="9" lg="5">
        <v-card class="mx-auto" outlined>
          <v-form class="ma-4" ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="marks"
              :rules="scoreRules"
              label="Marks"
              type="number"
              required
            ></v-text-field>
            <v-select
              v-model="paper"
              :items="gatescoreData.gatePapers"
              item-text="brName"
              item-value="brCode"
              :rules="[(v) => !!v || 'Branch is required']"
              label="GATE Paper / Branch"
              required
            ></v-select>

            <v-select
              v-model="category"
              :items="gatescoreData.categories"
              item-text="category"
              item-value="val"
              :rules="[(v) => !!v || 'Category is required']"
              label="Category"
              required
            ></v-select>

            <div class="text-center">
              <v-btn
                :disabled="!valid"
                color="success"
                class="mb-6 mt-5 justify-center wrap"
                @click="submit"
              >
                Calculate GATE Score
              </v-btn>
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="pt-15 justify-center mb-7">
      <v-col v-if="predictedMinMarks !== 0" lg="6" sm="8" cols="12">
        <v-card class="mx-1 mb-1">
          <v-card-title class="pa-6 mb-2">
            <p>Predicted GATE Score</p>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="pa-6 pt-0 justify-center" v-if="subscriptionInfo">
            <v-row no-gutters class="justify-center">
              <v-col class="my-auto mb-6 " align="center">
                <span style="font-size: 42px; font-weight:bold"
                  >{{ Number(predictedscore.toFixed(0)) }}
                </span>
              </v-col>
            </v-row>
            <v-row no-gutters class="justify-space-between">
              <div>
                <div class="subtext">
                  {{Number((predictedMinMarks * 1).toFixed(1))
                  }}
                </div>
                <div class="subtext-index success--text">~OC/EWS</div>
              </div>
              <div>
                <div class="subtext" >
                  {{ Number((predictedMinMarks * 0.9).toFixed(1))
                  }}
                </div>
                <div class="subtext-index success--text">~BC</div>
              </div>
              <div>
                <div class="subtext">
                  {{ Number((predictedMinMarks * 0.66).toFixed(1))
                  }}
                </div>
                <div class="subtext-index success--text">~SC/ST/PwD</div>
              </div>
            </v-row>
          </v-card-text>
          <v-card-text class="pa-6 pt-0 justify-center" v-else>
            <paymentCard />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div v-if="predictedMinMarks !== 0">
      <v-row>
        <v-col
          v-for="gscore in gatescoreData.gateStat"
          :key="gscore.year"
          lg="4"
          sm="6"
          cols="12"
        >
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6">
              <p>{{ gscore.year }} GATE Score</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-6 pt-0" v-if="gscore.year%2 || subscriptionInfo">
              <v-row no-gutters class="mb-3">
                <v-col class="my-auto" align="center">
                  <span style="font-size: 38px">{{ gscore.score }} </span>
                </v-col>
              </v-row>
              <v-row no-gutters class="justify-space-between">
                <div>
                  <div class="subtext">
                    {{ Number((gscore.min_marks * 1).toFixed(1))
                    }}
                  </div>
                  <div class="subtext-index warning--text">OC/EWS</div>
                </div>
                <div>
                  <div class="subtext">
                    {{ Number((gscore.min_marks * 0.9).toFixed(1))
                    }}
                  </div>
                  <div class="subtext-index warning--text">BC</div>
                </div>
                <div>
                  <div class="subtext">
                    {{ Number((gscore.min_marks * 0.66).toFixed(1))
                    }}
                  </div>
                  <div class="subtext-index warning--text">SC/ST/PwD</div>
                </div>
              </v-row>
            </v-card-text>
            <v-card-text class="pa-6 pt-0" v-else>
              <v-row no-gutters class="mb-3">
                <v-col class="my-auto mb-4" align="center">
                  <span style="font-size: 22px">Available to Pro Members</span>
                </v-col>
              </v-row>
              <v-row no-gutters class="justify-space-between">
                <paymentBtn />
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import gatescoreData from "./GatescoreData";
import { mapActions, mapGetters } from "vuex";
import paymentCard from "../Paymentcard/PaymentCard";
import paymentBtn from "../PaymentBtn/PaymentBtn"

export default {
  name: "Gatescore",
  components: {
    paymentCard,
    paymentBtn,
  },
  data: () => ({
    gatescoreData,
    search: "",
    valid: true,
    marks: null,
    scoreRules: [
      (v) => !!v || "Score is required",
      (v) => (v && v > 0 && v <= 100) || "GATE maks must be between 0 & 100",
    ],
    paper: null,
    category: null,
    predictedscore: null,
    predictedMinMarks: 0,
  }),
  computed:{
    ...mapGetters(["user", "isProChecked", "subscriptionInfo"]),
  },
  created: async function () {
    if (!this.user) {
      await this.getCurrentUser();
    }
    if(!this.isProChecked){
      await this.getSubsUser();
    }
  },
  methods: {
        ...mapActions(["getCurrentUser", "getSubsUser"]),
    submit() {
      var base_url;
      var url;
      // validate form
      this.$refs.form.validate();

      if (
        gatescoreData.paper === this.paper &&
        gatescoreData.gateStat !== null
      ) {
        this.calcuateScore();
      } else {
        base_url =
          "https://www.inspirenignite.com/wp-json/compare/v1/gatescore?paper=";
        // https://www.inspirenignite.com/wp-json/compare/v1/gatescore?paper=AE
        url = base_url + this.paper;
        // console.log(url);
        axios
          .get(url)
          .then((res) => {
            // console.log("stats return: ",res.data);
            this.gatescoreData.gateStat = res.data;
            this.gatescoreData.gateStat.sort((a, b) => (a.year > b.year ? -1 : 1));
            // this.totalVisits = parseInt(res.data);
          })
          .catch((error) => {
            console.log(error);
            // console.log(url);
            // this.errored = true;
          })
          .finally(() => this.calcuateScore());
        gatescoreData.paper = this.paper;
      }
    },
    calcuateScore() {
      // console.log('do some compute here');
      var predictedscore = 0;
      var predictedMinMarks = 0;
      var index = 0;
      var score = 0;
      var mtd = 0;
      // console.log(this.gatescoreData.gateStat);
      this.gatescoreData.gateStat.forEach((scoredata) => {
        // console.log(scoredata);
        var min_marks = 0;
        if (this.category === "BC") {
          min_marks = scoredata.min_marks * 0.9;
        } else if (this.category === "SC" || this.category === "ST") {
          min_marks = scoredata.min_marks * 0.6;
        } else {
          min_marks = scoredata.min_marks * 1;
        }
        mtd =
          ((900 - 350) * (scoredata.max_marks - min_marks)) /
            (scoredata.max_score - 350) +
          min_marks;
        score =
          350 + (900 - 350) * ((this.marks - min_marks) / (mtd - min_marks));
        score = score > 1000 ? 1000 : score;
        this.gatescoreData.gateStat[index].score = Number(score.toFixed(0));
        if (predictedMinMarks === 0) {
          predictedscore = score;
          predictedMinMarks = scoredata.min_marks * 1;
        } else {
          predictedscore = (predictedscore + score) / 2;
          // console.log(predictedMinMarks+'*'+scoredata.min_marks);
          predictedMinMarks = (predictedMinMarks + scoredata.min_marks * 1) / 2;
        }
        index = index + 1;
        // console.log(predictedMinMarks+'*'+scoredata.min_marks);
      });
      
      this.predictedscore = predictedscore > 1000 ? 1000 : predictedscore;
      this.predictedMinMarks = predictedMinMarks;
      // console.log(predictedMinMarks);
    },
  },
};
</script>

<style scoped src="./Gatescore.scss" lang="scss"></style>
