<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col v-if="chartItem.history_data">
        <ApexChart
          ref="cutoffChart"
          height="350"
          type="area"
          :options="chart.options"
          :series="chartItem.history_data.series"
        ></ApexChart>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import chart from "./chartOptions";
import ApexChart from "vue-apexcharts";

export default {
  props: ["chartItem"],
  name: "cutoffChart",
  components: {
    ApexChart,
  },
  data: () => ({
    chart,
  }),
  created: function () {
    // console.log(this.chartItem);
    this.chart.options = {
      ...this.chart.options,
      ...{
        xaxis: {
          categories: this.chartItem.history_data.year,
        },
      },
    };
  },
};
</script>

<style scoped src="./Cutoffchart.scss" lang="scss"></style>
212 623843723