<template>
  <v-navigation-drawer
    app
    clipped
    v-model="DRAWER_STATE"
    :mini-variant="!DRAWER_STATE"
    :width="sidebarWidth"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :temporary="$vuetify.breakpoint.smAndDown"
    :mini-variant-width="sidebarMinWidth"
    :class="{ 'drawer-mini': !DRAWER_STATE }"
  >
    <v-list>
      <template v-for="(item, i) in items">
        <v-row v-if="item.heading" :key="item.heading" align="center">
          <v-col cols="6">
            <span
              style="padding-left: 32px"
              class="text-body-1 subheader"
              :class="item.heading && DRAWER_STATE ? 'show ' : 'hide'"
            >
              {{ item.heading }}
            </span>
          </v-col>
          <v-col cols="6" class="text-center"> </v-col>
        </v-row>
        <v-divider
          v-else-if="item.divider"
          :key="i"
          dark
          class="my-4"
        ></v-divider>
        <v-list-group
          color="primary"
          v-else-if="item.children && DRAWER_STATE"
          :key="item.title"
          v-model="item.model"
          append-icon=""
        >
          <template v-slot:prependIcon>
            <v-icon size="28">mdi-image-filter-none</v-icon>
          </template>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="grey--text">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="(child, i) in item.children"
            :key="i"
            :to="child.link"
            link
          >
            <v-list-item-action v-if="child.icon">
              <v-icon size="">{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="grey--text">
                {{ child.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          color="primary"
          v-else-if="!item.action"
          :key="item.text"
          :to="item.link === '#' ? null : item.link"
          link
        >
          <v-list-item-action>
            <v-icon size="28" :color="item.color ? item.color : ''">{{
              item.icon
            }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="grey--text" link>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          color="primary"
          v-else
          :key="item.text"
          @click="item.action"
          link
        >
          <v-list-item-action>
            <v-icon size="28" :color="item.color ? item.color : ''">{{
              item.icon
            }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="grey--text" link>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from "vuex";
import firebase from 'firebase/app';

export default {
  props: {
    source: String,
  },
  data() {
    return {
      items: [
        {
          title: "Profile",
          icon: "mdi-account",
          link: "/profile",
          action: null,
        },
        {
          title: "Overview",
          icon: "mdi-home",
          link: "/dashboard",
          action: null,
        },
        {
          title: "Gate Score",
          icon: "mdi-scale-balance",
          link: "/gatescore",
          action: null,
        },
        {
          title: "Cutoffs",
          icon: "mdi-format-size",
          link: "/cutoff",
          action: null,
        },
        {
          title: "Compare",
          icon: "mdi-grid-large",
          link: "/compare",
          action: null,
        },
        {
          title: "Choice Filling",
          icon: "mdi-image-filter-none",
          link: "/choices",
          action: null,
        },
        {
          title: "Admissions",
          icon: "mdi-bell-outline",
          link: "/admissions",
          action: null,
        },
        { divider: true, action: null },
        { heading: "HELP" },
        { title: "Community", icon: "mdi-book-variant-multiple", action: null },
        { title: "Support", icon: "mdi-forum", action: null },
        { title: "FAQ", icon: "mdi-help-circle-outline", link: "/faqs", action: null },
        // {
        //   lougout: true,
        //   title: "Manage Account",
        //   icon: "mdi-logout",
        //   action: this.sendToCustomerPortal,
        // },
        { divider: true, action: null },
        {
          lougout: true,
          title: "Logout",
          icon: "mdi-logout",
          action: this.logout,
        },
      ],
      sidebarWidth: 240,
      sidebarMinWidth: 96,
    };
  },
  computed: {
    ...mapState({ drawer: (state) => state.drawer.drawer }),
    DRAWER_STATE: {
      get() {
        return this.drawer;
      },
      set(newValue) {
        if (newValue === this.drawer) return;
        this.TOGGLE_DRAWER();
      },
    },
  },
  methods: {
    ...mapActions(["TOGGLE_DRAWER", "googleSignOut"]),
    logout: async function () {
      window.localStorage.setItem("authenticated", false);
      await this.googleSignOut();
      this.$router.push("/login");
    },
    async sendToCustomerPortal() {
    // had to update firebase.app().functions() to firebase.default.functions() and
    // removed the region from the functions call (from stripe firebase extension docs)
    const functionRef = firebase
      .functions()
      .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    const { data } = await functionRef({ returnUrl: window.location.origin });
    window.location.assign(data.url);
  },
  },
};
</script>

<style src="./Sidebar.scss" lang="scss"/>
