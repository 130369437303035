import Vue from 'vue';
import Router from 'vue-router';

import Layout from '@/components/Layout/Layout';

// Pages
import Dashboard from '@/pages/Dashboard/Dashboard';
import Notifications from '@/pages/Notifications/Notifications'
import Charts from '@/pages/Charts/Charts'
import Error from "@/pages/Error/Error";
import Login from "@/pages/Login/Login";
import Compare from '@/pages/Compare/Compare';
import Cutoff from '@/pages/Cutoff/Cutoff';
import Gatescore from '@/pages/Gatescore/Gatescore'
import Admissions from '@/pages/Admissions/Admissions'
import Profile from '@/pages/Profile/Profile';
import Choices from '@/pages/Choices/Choices';
import Faqs from '@/pages/Faqs/Faqs';
import firebase from 'firebase/app';
import VueAnalytics from 'vue-analytics'


Vue.use(Router);

Vue.use(VueAnalytics, {
  id: '264144204',
  checkDuplicatedScript: true
});

let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/',
      redirect: 'dashboard',
      name: 'Layout',
      component: Layout,
      children: [
        {
          path: 'profile',
          name: 'Profile',
          component: Profile,
        },
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
        },
        {
          path: 'gatescore',
          name: 'Gatescore',
          component: Gatescore,
        },
        {
          path: 'cutoff',
          name: 'Cutoff',
          component: Cutoff,
        },
        {
          path: 'choices',
          name: 'Choices',
          component: Choices
        },
        {
          path: 'compare',
          name: 'Compare',
          component: Compare
        },
        {
          path: 'admissions',
          name: 'Admissions',
          component: Admissions,
        },
        {
          path: 'notifications',
          name: 'Notifications',
          component: Notifications
        },
        {
          path: 'faqs',
          name: 'Faqs',
          component: Faqs
        },
        {
          path: 'charts',
          name: 'Charts',
          component: Charts
        },
      ],
    },
    {
      path: '*',
      name: 'Error',
      component: Error
    }
  ],
});

// // Nav guards
router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !firebase.auth().currentUser) { 
    console.log('auth missing :', firebase.auth().currentUser);
    next({ name: 'Login' }); 
  } else if (to.name == 'Login' && firebase.auth().currentUser) { 
    next({name: 'Dashboard'});
  } else{
    next();
  }
});

export default router;