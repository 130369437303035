<template>
  <v-btn
    elevation="1"
    @click="sendToCheckout"
    color="secondary"
    class="text-capitalize button-shadow white--text mr-1"
    :loading="isLoading"
    >Get Memebership</v-btn
  >
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { db } from "@/services/firebase";
import { loadStripe } from "@stripe/stripe-js";
export default {
  name: "paymentBtn",
  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["getCurrentUser", "sendToCheckout"]),
    async sendToCheckout() {
      this.isLoading = true;
      if (this.user === null) {
        this.getCurrentUser();
      }
      // console.log(this.user);
      // await this.sendToCheckout(this.user);
      // console.log('reached end of dash pay');
      // get current user
      console.log(this.user.uid);
      const docRef = await db
        .collection("customers")
        .doc(this.user.uid)
        .collection("checkout_sessions")
        .add({
          price: "price_1MPDpaJRXzc5QFIxITL90e66",
          mode:"payment",
          success_url: window.location.origin,
          cancel_url: window.location.origin,
        });
        console.log(docRef);

      // Wait for the CheckoutSession to get attached by the extension
      docRef.onSnapshot(async (snap) => {
        const { error, sessionId } = snap.data();
        if (error) {
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          alert(`An error occured: ${error.message}`);
        }
        if (sessionId) {
          // We have a session, let's redirect to Checkout
          // Init Stripe
          const stripe = await loadStripe(
            "pk_live_51HQbKcJRXzc5QFIxJSR8wr8v6jHfLDiAw3Auz2mWpP3J9myZq4qVJYnQTo4xVHbQR5e6AAsh6Yu6Lmuj9WfqHCs600pjXA9zhl"
          );
          console.log("redirecting", sessionId);
          stripe.redirectToCheckout({ sessionId });
        }
      }).then(()=>this.isLoading=false)
      .catch((error)=>console.log(error));
    },
  },
};
</script>

<style scoped src="./PaymentBtn.scss" lang="scss"></style>